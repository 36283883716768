<template>
  <div class="home-index"  v-if="accountInfo.langByLanguage">
    <div class="pb-2 d-flex justify-content-end">
      <div class="pe-1">
        <el-date-picker
          v-model="filter.day"
          type="date"
          :clearable="false"
          format="dd/MM/yyyy"
          placeholder="Ngày tạo"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </div>
    </div>
    <div class="row" v-loading="loading">
      <div class="col-md-6">
        <div class="item-home">
          <div class="title">
            <span>{{ accountInfo.langByLanguage.home_total_bags_of_the_day }}</span>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <span class="count">{{ dataInHomeByDay.total_bag }}</span>
            <div class="d-flex align-items-center">
              <div
                class="icon d-flex flex-column justify-content-center align-items-center"
              >
                <span class="line-1">{{
                  dataInHomeByDay.total_weight
                }}</span>
                <span>/kg</span>
              </div>
              <div
                class="icon d-flex flex-column justify-content-center align-items-center"
              >
                <span class="line-2">{{
                  dataInHomeByDay.total_volumn
                }}</span>
                <span>/m3</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="item-home">
          <div class="title">
            <span>{{ accountInfo.langByLanguage.home_total_packs_of_the_day }}</span>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <span class="count">{{ dataInHomeByDay.total_pack }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "HomeIndex",
  metaInfo() {
    return {
      title: "Trang chủ",
    };
  },
  components: {},
  data() {
    return {
      loading: true,
      filter: {
        day: "",
      },
      dataInHomeByDay: {
        count_bag: 0,
        bag_total_weight: 0,
        bag_total_volumn: 0,
        count_pack: 0,
      },
      listPerIndex: [],
      pathFund: "",
    };
  },
  computed: {
    ...mapGetters("account", ["accountInfo"]),
  },
  mounted() {
    this.initDateCurrent();
    // this.fetchDatabaseModel();
    this.listPermissionIndex();
    this.checkRouterPush();
  },
  methods: {
    // check per index
    listPermissionIndex() {
      let permissions = [];
      if (
        undefined !== this.accountInfo.permissions &&
        this.accountInfo.permissions.length > 0
      ) {
        this.accountInfo.permissions.forEach((e) => {
          permissions.push(e.name);
        });
      }
      // trang chủ
      if(permissions.includes("home.index")) {
        this.listPerIndex.push("home.index")
      }
      // Nhập kho
      if(permissions.includes("inwarehouse.index")) {
        this.listPerIndex.push("inwarehouse.index")
      }
      // DS kiện hàng
      if(permissions.includes("pack.index")) {
        this.listPerIndex.push("pack.index")
      }
      // ĐB quét mã
      if(permissions.includes("scanandclosebag.index")) {
        this.listPerIndex.push("scanandclosebag.index")
      }
      // DS bao hàng
      if(permissions.includes("bag.index")) {
        this.listPerIndex.push("bag.index")
      }
      // Phiếu điều xe
      if(permissions.includes("bill.index")) {
        this.listPerIndex.push("bill.index")
      }
      // BC nhập kho
      if(permissions.includes("report-in-warehouse.index")) {
        this.listPerIndex.push("report-in-warehouse.index")
      }
      // DS kho
      if(permissions.includes("warehouse.index")) {
        this.listPerIndex.push("warehouse.index")
      }
      // Ds công ty
      if(permissions.includes("company.index")) {
        this.listPerIndex.push("company.index")
      }
      // DS xe
      if(permissions.includes("car.index")) {
        this.listPerIndex.push("car.index")
      }
      //QL nhân viên
      if(permissions.includes("user.index")) {
        this.listPerIndex.push("user.index")
      }
      // ngôn ngữ
      if(permissions.includes("language.update")) {
        this.listPerIndex.push("language.update")
      }
      // Phân quyền
      if(permissions.includes("per.index")) {
        this.listPerIndex.push("per.index")
      }
      // Kiểm bao
      if(permissions.includes("checkbag.index")) {
        this.listPerIndex.push("checkbag.index")
      }
      // lệnh điều chuyển
      if(permissions.includes("command.index")) {
        this.listPerIndex.push("command.index")
      }
    },
    // chuyển màn tương ứng vs per
    checkRouterPush() {
      if(this.listPerIndex.includes("home.index") == false) {
        // Nhập kho
        if(this.listPerIndex[0] == "inwarehouse.index") {
          this.pathFund = "/warehouse-list"
          if(this.full_path !== this.pathFund) {
            this.$router.push({ name: "warehouse-list" });
          }
        }
        // ds kiện hàng
        if(this.listPerIndex[0] == "pack.index") {
          this.pathFund = "/pack-list"
          if(this.full_path !== this.pathFund) {
            this.$router.push({ name: "pack-list" });
          }
        }
        // ĐB quét mã
        if(this.listPerIndex[0] == "scanandclosebag.index") {
          this.pathFund = "/paste-code"
          if(this.full_path !== this.pathFund) {
            this.$router.push({ name: "paste-code" });
          }
        }
        // ds bao hàng
        if(this.listPerIndex[0] == "bag.index") {
          this.pathFund = "/bag"
          if(this.full_path !== this.pathFund) {
            this.$router.push({ name: "bag-index" });
          }
        }
        // phiếu điều xe
        if(this.listPerIndex[0] == "bill.index") {
          this.pathFund = "/bill"
          if(this.full_path !== this.pathFund) {
            this.$router.push({ name: "bill-index" });
          }
        }
        // báo cáo nhập kho
        if(this.listPerIndex[0] == "report-in-warehouse.index") {
          this.pathFund = "/report-list"
          if(this.full_path !== this.pathFund) {
            this.$router.push({ name: "report-list" });
          }
        }
        // ds kho
        if(this.listPerIndex[0] == "warehouse.index") {
          this.pathFund = "/warehouse"
          if(this.full_path !== this.pathFund) {
            this.$router.push({ name: "warehouse-index" });
          }
        }
        // ds công ty 
        if(this.listPerIndex[0] == "company.index") {
          this.pathFund = "/company"
          if(this.full_path !== this.pathFund) {
            this.$router.push({ name: "company-index" });
          }
        }
        // ds xe
        if(this.listPerIndex[0] == "car.index") {
          this.pathFund = "/car"
          if(this.full_path !== this.pathFund) {
            this.$router.push({ name: "car-index" });
          }
        }
        // ds nhân viên
        if(this.listPerIndex[0] == "user.index") {
          this.pathFund = "/agents"
          if(this.full_path !== this.pathFund) {
            this.$router.push({ name: "agents-index" });
          }
        }
        // ngôn ngữ 
        if(this.listPerIndex[0] == "language.update") {
          this.pathFund = "/language-list"
          if(this.full_path !== this.pathFund) {
            this.$router.push({ name: "language-list" });
          }
        }
        // phân quyền
        if(this.listPerIndex[0] == "per.index") {
          this.pathFund = "/roles"
          if(this.full_path !== this.pathFund) {
            this.$router.push({ name: "role-index" });
          }
        }
        // Kiểm bao
        if(this.listPerIndex[0] == "checkbag.index") {
          this.pathFund = "/check-bag"
          if(this.full_path !== this.pathFund) {
            this.$router.push({ name: "check-bag" });
          }
        }
        // ds lệnh điều chuyển
        if(this.listPerIndex[0] == "command.index") {
          this.pathFund = "/transfer-list"
          if(this.full_path !== this.pathFund) {
            this.$router.push({ name: "transfer-list" });
          }
        }
      }

      
    },
    //
    initDateCurrent() {
      if (this.filter.day == "") {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, "0");
        let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = today.getFullYear();
        let date_start = yyyy + "-" + mm + "-" + dd;
        this.filter.day = date_start;
      }
    },
    fetchDatabaseModel() {
      this.loading = true;
      axios.post("pack/get-statistic-day-in-home", this.filter).then((response) => {
        this.loading = false;
        switch (response.code) {
          case 200:
            this.dataInHomeByDay = response.data;
            break;
          case 400:
            this.$notify({
              title: "Có lỗi xảy ra",
              message: response.errors[0],
              type: "warning",
            });
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },
  },
  watch: {
    "filter.day": function () {
      this.fetchDatabaseModel();
    },
  },
};
</script>

<style lang="css" scoped>
.home-index {
  min-height: 100vh;
  padding: 50px 100px 50px 50px;
}
.item-home {
  padding: 16px;
  background: #2ca02c;
  border-radius: 15px;
  color: #fff;
  min-height: 150px;
  margin-top: 10px;
}
.item-home .title {
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 40px;
}
.item-home .count {
  font-size: 28px;
  min-height: 52px;
}
.item-home .icon {
  background-color: #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-left: 10px;
  line-height: 16px;
}
.item-home .icon span {
  color: #858484;
  font-size: 11px;
}
.item-home .icon .line-1 {
  font-size: 14px;
  color: #f33131;
  font-weight: 500;
}
.item-home .icon .line-2 {
  font-size: 14px;
  color: #2ca02c;
  font-weight: 500;
}
</style>

<style type="text/css">
.home-index .el-input__inner {
  border-radius: 20px;
  font-size: 13px;
  height: 33px;
  color: #2ca02c;
}
.home-index .el-input__icon {
  line-height: 33px;
  color: #2ca02c;
}
</style>
