import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/home/index.vue";

import Meta from "vue-meta";
import auth from "@/router/auth";

Vue.use(VueRouter);

Vue.use(Meta, {
  keyName: "metaInfo", // the component option name that vue-meta looks for meta info on.
  attribute: "data-vue-meta", // the attribute name vue-meta adds to the tags it observes
  ssrAttribute: "data-vue-meta-server-rendered", // the attribute name that lets vue-meta know that meta info has already been server-rendered
  tagIDKeyName: "vmid", // the property name that vue-meta uses to determine whether to overwrite or append a tag
});

const routes = [
  {
    path: "/",
    name: "home-index",
    component: Home,
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/login",
    name: "account-login",
    component: () => import("@/views/account/Login.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/register",
    name: "account-register",
    component: () => import("@/views/account/Register.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  // Profile
  {
    path: "/profile",
    name: "account-profile",
    component: () => import("@/views/account/Profile.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/print-bill/:bag_id",
    name: "print-bill",
    component: () => import("@/views/print/Index.vue"),
    beforeEnter: auth.requireAuthV2,
  },

  // in bill
  {
    path: "/print-bill-code/:id",
    name: "print-bill-code",
    component: () => import("@/views/print/InBill.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  // in phiếu điều xe
  {
    path: "/print-bill-car/:id",
    name: "print-bill-car",
    component: () => import("@/views/print/InBillCar.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  // in danh sách bao
  {
    path: "/print-bill-bag/:id",
    name: "print-bill-bag",
    component: () => import("@/views/print/InBillBag.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  // in lênh điều chuyển
  {
    path: "/print-command/:id",
    name: "print-command",
    component: () => import("@/views/print/InCommand.vue"),
    beforeEnter: auth.requireAuthV2,
  },

  // Module Đơn hàng
  {
    path: "/order/create",
    name: "order-create",
    component: () => import("@/views/order/Create.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/order-lo/create",
    name: "order-lo-create",
    component: () => import("@/views/order/CreateLo.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/order",
    name: "order-index",
    component: () => import("@/views/order/Index.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/order/show/:order_code/:order_status",
    name: "order-show",
    component: () => import("@/views/order/Show.vue"),
    beforeEnter: auth.requireAuth,
  },

  // Module Kiện hàng
  {
    path: "/pack",
    name: "pack-index",
    component: () => import("@/views/pack/Index.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/pack-list",
    name: "pack-list",
    component: () => import("@/views/pack/ListV2.vue"),
    beforeEnter: auth.requireAuth,
  },
  // {
  //   path: "/pack-list-v2",
  //   name: "pack-list-v2",
  //   component: () => import("@/views/pack/List.vue"),
  //   beforeEnter: auth.requireAuth,
  // },

  {
    path: "/pack-list-realtime",
    name: "pack-list-realtime",
    component: () => import("@/views/pack/ListRealTime.vue"),
    beforeEnter: auth.requireAuth,
  },

  {
    path: "/paste-code",
    name: "paste-code",
    component: () => import("@/views/pack/paste-code.vue"),
    beforeEnter: auth.requireAuth,
  },

  // Module Bao hàng
  {
    path: "/bag",
    name: "bag-index",
    component: () => import("@/views/bag/Index.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/bag/show/:bag_id/:bag_status",
    name: "bag-show",
    component: () => import("@/views/bag/Show.vue"),
    beforeEnter: auth.requireAuth,
  },

  // Module Công ty
  {
    path: "/company",
    name: "company-index",
    component: () => import("@/views/company/Index.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/company/create",
    name: "company-create",
    component: () => import("@/views/company/Create.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/company/update/:id",
    name: "company-update",
    component: () => import("@/views/company/Update.vue"),
    beforeEnter: auth.requireAuth,
  },

  // Module Kho
  {
    path: "/warehouse",
    name: "warehouse-index",
    component: () => import("@/views/warehouse/Index.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/warehouse/create",
    name: "warehouse-create",
    component: () => import("@/views/warehouse/Create.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/warehouse/update/:id",
    name: "warehouse-update",
    component: () => import("@/views/warehouse/Update.vue"),
    beforeEnter: auth.requireAuth,
  },

  // Module Xe
  {
    path: "/car",
    name: "car-index",
    component: () => import("@/views/car/Index.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/car/create",
    name: "car-create",
    component: () => import("@/views/car/Create.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/car/update/:id",
    name: "car-update",
    component: () => import("@/views/car/Update.vue"),
    beforeEnter: auth.requireAuth,
  },

  // Module Bill
  {
    path: "/bill",
    name: "bill-index",
    component: () => import("@/views/bill/IndexV2.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/bill/show/:bill_id/:bill_status",
    name: "bill-show",
    component: () => import("@/views/bill/Show.vue"),
    beforeEnter: auth.requireAuth,
  },
  // Module Danh sách nhân viên
  {
    path: "/agents",
    name: "agents-index",
    component: () => import("@/views/agents/Index.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/agents/update/:id",
    name: "agents-update",
    component: () => import("@/views/agents/Update.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/agents/create/",
    name: "agents-create",
    component: () => import("@/views/agents/Create.vue"),
    beforeEnter: auth.requireAuth,
  },
  // Module phân quyền
  {
    path: "/roles",
    name: "roles-index",
    component: () => import("@/views/roles/Index.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/roles/update/:role_id",
    name: "roles-update",
    component: () => import("@/views/roles/Update.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/roles/create/",
    name: "roles-create",
    component: () => import("@/views/roles/Create.vue"),
    beforeEnter: auth.requireAuth,
  },

  // report
  {
    path: "/report-list",
    name: "report-list",
    component: () => import("@/views/report/List.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/warehouse-list",
    name: "warehouse-list",
    component: () => import("@/views/report/WarehouseListV2.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/warehouse-list-v2",
    name: "warehouse-list-v2",
    component: () => import("@/views/report/WarehouseListV2.vue"),
    beforeEnter: auth.requireAuth,
  },
  // thist lập ngôn ngữ
  {
    path: "/report-list",
    name: "report-list",
    component: () => import("@/views/report/List.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/report-import",
    name: "report-import",
    component: () => import("@/views/report/Inventory.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/report-export",
    name: "report-export",
    component: () => import("@/views/report/Inventory.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/report-inventory",
    name: "report-inventory",
    component: () => import("@/views/report/Inventory.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/report-lost-goods",
    name: "report-lost-goods",
    component: () => import("@/views/report/LostGoods.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/language-list",
    name: "language-list",
    component: () => import("@/views/language/List.vue"),
    beforeEnter: auth.requireAuth,
  },
  // kiểm bao
  {
    path: "/check-bag",
    name: "check-bag",
    component: () => import("@/views/check/bag.vue"),
    beforeEnter: auth.requireAuth,
  },
  // kiểm hàng
  {
    path: "/check-pack/:id",
    name: "check-pack",
    component: () => import("@/views/check/pack.vue"),
    beforeEnter: auth.requireAuth,
  },
  // ds lệnh điều chuyển
  {
    path: "/transfer-list",
    name: "transfer-list",
    component: () => import("@/views/transfer/List.vue"),
    beforeEnter: auth.requireAuth,
  },
  // cập nhật hàng chờ xác nhận
  {
    path: "/pack-unconfirmed",
    name: "pack-unconfirmed",
    component: () => import("@/views/pack/PackUnconfirmedV2.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/pack-unconfirmed-v2",
    name: "pack-unconfirmed-v2",
    component: () => import("@/views/pack/PackUnconfirmedV2.vue"),
    beforeEnter: auth.requireAuth,
  },
  // export invoce
  {
    path: "/export-invoce",
    name: "export-invoce",
    component: () => import("@/views/export/Index.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/pack-list-error",
    name: "pack-list-error",
    component: () => import("@/views/pack/error.vue"),
    beforeEnter: auth.requireAuth,
  },
  // in bao hàng
  {
    path: "/print-bag/:id",
    name: "print-bag",
    component: () => import("@/views/print/InBag.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  // Chọn xe
  // {
  //   path: "/choose-car",
  //   name: "choose-car",
  //   component: () => import("@/views/car/Choose.vue"),
  //   beforeEnter: auth.requireAuthV2,
  // },
  {
    path: "/choose-car-v2",
    name: "choose-car-v2",
    component: () => import("@/views/car/ChooseV2.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  // hàng lô
  {
    path: "/pack-batch",
    name: "pack-batch",
    component: () => import("@/views/batch/List.vue"),
    beforeEnter: auth.requireAuth,
  },
  // Kiểm bao theo phiếu điều xe
  {
    path: "/check-bag-by-bill/:bill_id",
    name: "check-bag-by-bill",
    component: () => import("@/views/bag/CheckBagByBill.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/customer-list",
    name: "customer-list",
    component: () => import("@/views/customer/List.vue"),
    beforeEnter: auth.requireAuth,
  },
  // dỡ hàng
  {
    path: "/unloading-list",
    name: "unloading-list",
    component: () => import("@/views/unloading/List.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/unloading/edit/:id",
    name: "unloading-edit",
    component: () => import("@/views/unloading/Edit.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/unloading/show/:id",
    name: "unloading-show",
    component: () => import("@/views/unloading/Show.vue"),
    beforeEnter: auth.requireAuth,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
