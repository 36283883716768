<template>
  <div class="component-menu-left">
    <div
      class="name-project d-flex justify-content-between align-items-center"
      :class="[showText == false ? 'name-project-lite' : '']"
    >
      <!-- <div></div> -->
      <img
        @click="goto('home-index')"
        src="@/static/images/logo/orderbiz1.svg"
        alt=""
        v-if="showText == true"
        style="padding-right: 10px !important;"
      />
      <img
        src="@/static/icon/menu-left/action.svg"
        alt=""
        @click="toggleMenu()"
      />
    </div>
    <el-menu
      default-active="2"
      class="el-menu-vertical-demo menu-tree item-left"
      :collapse="!showText"
      :unique-opened="true"
    >
      <el-submenu
        index="1"
        v-if="
          hasPermission('warehouse_list.index') ||
          hasPermission('pack_list_realtime.index') ||
          hasPermission('pack_unconfirmed.index') ||
          hasPermission('paste_code.index') ||
          hasPermission('bag.index') ||
          hasPermission('pack_batch.index') ||
          hasPermission('bill.index')
        "
      >
        <template slot="title">
          <img src="@/static/icon/menu-left/warehousetq.svg" alt="" />
          <span slot="title" class="text-white">{{ translateLanguage.kho_hang_cn }}</span>
        </template>
        <el-menu-item-group>
          <router-link class="spanLink" :to="{ name: 'warehouse-list' }" v-if="hasPermission('warehouse_list.index')">
            <el-menu-item
              index="1-1"
              class="item-left"
              >{{
                accountInfo.langByLanguage.import_stock_title_menu
              }}</el-menu-item
            >
          </router-link>
          <!-- Danh sách kiện hàng realtime -->
          <router-link class="spanLink" :to="{ name: 'pack-list-realtime' }" v-if="hasPermission('pack_list_realtime.index')">
            <el-menu-item
              index="1-2"
              class="item-left"
              >{{
                accountInfo.langByLanguage.pack_list_realtime_title_menu
              }}</el-menu-item
            >
          </router-link>
          <router-link class="spanLink" :to="{ name: 'pack-unconfirmed' }" v-if="hasPermission('pack_unconfirmed.index')">
            <el-menu-item
              index="1-3"
              class="item-left"
              >{{
                accountInfo.langByLanguage.pack_unconfirmed_title_menu
              }}</el-menu-item
            >
          </router-link>
          <!-- Đóng bao quét mã -->
          <router-link class="spanLink" :to="{ name: 'paste-code' }" v-if="hasPermission('paste_code.index')">
            <el-menu-item
              index="1-4"
              class="item-left"
              >{{
                accountInfo.langByLanguage.paste_code_title_menu
              }}</el-menu-item
            >
          </router-link>
          <!-- Bao hàng -->
          <router-link class="spanLink" :to="{ name: 'bag-index' }" v-if="hasPermission('bag.index')">
            <el-menu-item
              index="1-5"
              class="item-left"
              >{{ accountInfo.langByLanguage.bag_title_menu }}</el-menu-item
            >
          </router-link>
          <!-- Lô hàng -->
          <router-link class="spanLink" :to="{ name: 'pack-batch' }" v-if="hasPermission('pack_batch.index')">
            <el-menu-item
              index="1-6"
              class="item-left"
            >{{ accountInfo.langByLanguage.paste_code_pack_lo }}</el-menu-item>
          </router-link>
          <!-- Phiếu điều xe -->
          <router-link class="spanLink" :to="{ name: 'bill-index' }" v-if="hasPermission('bill.index')">
            <el-menu-item
              index="1-7"
              class="item-left"
              >{{ accountInfo.langByLanguage.bill_title_menu }}</el-menu-item
            >
          </router-link>
          <!--  dỡ hàng        //-->
          <router-link class="spanLink" :to="{ name: 'unloading-list' }" v-if="hasPermission('unload.index')">
            <el-menu-item
              index="1-8"
              class="item-left"
            >{{ translateLanguage.do_hang }}</el-menu-item
            >
          </router-link>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu
        index="2"
        v-if="hasPermission('checkbag.index') || hasPermission('transfer_list.index')"
      >
        <template slot="title">
          <img src="@/static/icon/menu-left/warehousevn.svg" alt="" />
          <span slot="title" class="text-white">{{ translateLanguage.kho_hang_vn }}</span>
        </template>
        <el-menu-item-group>
          <!-- Kiểm bao -->
          <router-link class="spanLink" :to="{ name: 'check-bag' }" v-if="hasPermission('check_bag.index')">
            <el-menu-item
              index="2-1"
              class="item-left"
              >{{ accountInfo.langByLanguage.check_bag_title_menu }}</el-menu-item
            >
          </router-link>
          <!-- Lệnh điều chuyển -->
          <router-link class="spanLink" :to="{ name: 'transfer-list' }" v-if="hasPermission('transfer_list.index')">
            <el-menu-item
              index="2-2"
              class="item-left"
              >{{ accountInfo.langByLanguage.command_title_menu }}</el-menu-item
            >
          </router-link>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu
        index="3"
        v-if="hasPermission('pack_list.index') || hasPermission('pack_list_error.index') || hasPermission('report_lost_goods.index')"
      >
        <template slot="title">
          <img src="@/static/icon/menu-left/warehousevn.svg" alt="" />
          <span slot="title" class="text-white">{{ accountInfo.langByLanguage.pack_list_title_menu }}</span>
        </template>
        <el-menu-item-group>
          <router-link class="spanLink" :to="{ name: 'pack-list' }" v-if="hasPermission('pack_list.index')">
            <el-menu-item
              index="3-1"
              class="item-left"
              >{{ accountInfo.langByLanguage.pack_list_title_menu }}</el-menu-item
            >
          </router-link>
          <router-link class="spanLink" :to="{ name: 'pack-list-error' }" v-if="hasPermission('pack_list_error.index')">
            <el-menu-item
              index="3-2"
              class="item-left"
              >{{
                accountInfo.langByLanguage.pack_list_error_title
              }}</el-menu-item
            >
          </router-link>
          <router-link class="spanLink" :to="{ name: 'report-lost-goods' }" v-if="hasPermission('report_lost_goods.index')">
            <el-menu-item
              index="3-3"
              class="item-left"
              >{{ accountInfo.langByLanguage.lost_goods_title_menu }}</el-menu-item
            >
          </router-link>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="4" v-if="hasPermission('report.report_import') || hasPermission('report.report_export') || hasPermission('report.report_inventory')">
        <template slot="title">
          <img src="@/static/icon/menu-left/report.svg" alt="" />
          <span slot="title" class="text-white">{{ translateLanguage.bao_cao }}</span>
        </template>
        <el-menu-item-group>
          <!-- Nhập kho -->
          <router-link class="spanLink" :to="{ name: 'report-import' }" v-if="hasPermission('report.report_import')">
            <el-menu-item
              index="4-1"
              class="item-left"
              >{{
                accountInfo.langByLanguage.report_list_title_menu
              }}</el-menu-item
            >
          </router-link>
          <router-link class="spanLink" :to="{ name: 'report-export' }" v-if="hasPermission('report.report_export')">
            <el-menu-item
              index="4-2"
              class="item-left"
              >{{ accountInfo.langByLanguage.report_export_title_menu }}</el-menu-item
            >
          </router-link>
          <router-link class="spanLink" :to="{ name: 'report-inventory' }" v-if="hasPermission('report.report_inventory')">
            <el-menu-item
              index="4-3"
              class="item-left"
              >{{ accountInfo.langByLanguage.report_inventory_title_menu }}</el-menu-item
            >
          </router-link>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="5" v-if="hasPermission('invoce.export_manifest')">
        <template slot="title">
          <img src="@/static/icon/menu-left/book_open.svg" alt="" />
          <span slot="title" class="text-white">{{ translateLanguage.lam_to_khai_thue }}</span>
        </template>
        <el-menu-item-group>
          <!-- Xuất Manifest -->
          <router-link class="spanLink" :to="{ name: 'export-invoce' }" v-if="hasPermission('invoce.export_manifest')">
            <el-menu-item
              index="5-1"
              class="item-left"
              >{{
                accountInfo.langByLanguage.manifest_title
              }}</el-menu-item
            >
          </router-link>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu
        index="6"
        v-if="hasPermission('customer.index')">
        <template slot="title">
          <img src="@/static/icon/menu-left/user1.svg" alt="" />
          <span slot="title" class="text-white">{{
            accountInfo.langByLanguage.customer_title_menu
          }}</span>
        </template>
        <el-menu-item-group>
          <router-link class="spanLink" :to="{ name: 'customer-list' }" v-if="hasPermission('customer.index')">
            <el-menu-item
              index="6-1"
              class="item-left"
              >{{
                accountInfo.langByLanguage.customer_list
              }}</el-menu-item
            >
          </router-link>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu
        index="7"
        v-if="hasPermission('user.index') || hasPermission('per.index')"
      >
        <template slot="title">
          <img src="@/static/icon/menu-left/user1.svg" alt="" />
          <span slot="title" class="text-white">{{ translateLanguage.tai_khoan }}</span>
        </template>
        <el-menu-item-group>
          <!-- Nhân viên -->
          <router-link class="spanLink" :to="{ name: 'agents-index' }" v-if="hasPermission('user.index')">
            <el-menu-item
              index="7-1"
              class="item-left"
              >{{ accountInfo.langByLanguage.agents_title_menu }}</el-menu-item
            >
          </router-link>
          <!-- Phân quyền -->
          <router-link class="spanLink" :to="{ name: 'roles-index' }" v-if="hasPermission('per.index')">
            <el-menu-item
              index="7-2"
              class="item-left"
              >{{ accountInfo.langByLanguage.roles_title_menu }}</el-menu-item
            >
          </router-link>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu
        index="8"
        v-if="
          hasPermission('company.index') ||
          hasPermission('car.index') ||
          hasPermission('language.index') ||
          hasPermission('warehouse.index')
        "
      >
        <template slot="title">
          <img src="@/static/icon/menu-left/setting.svg" alt="" />
          <span slot="title" class="text-white">{{ translateLanguage.cau_hinh }}</span>
        </template>
        <el-menu-item-group>
          <!-- Công ty -->
          <router-link class="spanLink" :to="{ name: 'company-index' }"  v-if="hasPermission('company.index')">
            <el-menu-item
              index="8-1"
              class="item-left"
              >{{ accountInfo.langByLanguage.company_title_menu }}</el-menu-item
            >
          </router-link>
          <!-- Xe -->
          <router-link class="spanLink" :to="{ name: 'car-index' }" v-if="hasPermission('car.index')">
            <el-menu-item
              index="8-2"
              class="item-left"
              >{{ accountInfo.langByLanguage.car_title_menu }}</el-menu-item
            >
          </router-link>
          <!-- Ngôn ngữ -->
          <router-link class="spanLink" :to="{ name: 'language-list' }" v-if="hasPermission('language.index')">
            <el-menu-item
              index="8-3"
              class="item-left"
              >{{ accountInfo.langByLanguage.lang_title_menu }}</el-menu-item
            >
          </router-link>
          <!-- Danh sách kho -->
          <router-link class="spanLink" :to="{ name: 'warehouse-index' }" v-if="hasPermission('warehouse.index')">
            <el-menu-item
              index="8-4"
              class="item-left"
              >{{ accountInfo.langByLanguage.warehouse_title_menu }}</el-menu-item
            >
          </router-link>
        </el-menu-item-group>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
import ls from "local-storage";
import { mapGetters } from "vuex";
export default {
  name: "LayoutMenuLeft",
  data() {
    return {
      showText: false,
      listPerIndex: [],
      pathFund: "",
      translateLanguage:{
        kho_hang_cn: "",
        kho_hang_vn: "",
        bao_cao: "",
        lam_to_khai_thue: "",
        tai_khoan: "",
        cau_hinh: "",
        do_hang: "",
      }
    };
  },
  computed: {
    ...mapGetters(["path", "full_path"]),
    ...mapGetters("account", ["accountInfo"]),
    ...mapGetters("account", ["logged"]),
  },
  mounted() {
    this.checkLogin();
    this.translate();
    console.log(this.$route.name)
  },
  methods: {
    translate(){
      if (this.accountInfo.language === 'en')
      {
        this.translateLanguage.kho_hang_cn = "Warehouse (CN)";
        this.translateLanguage.kho_hang_vn = "Warehouse (VN)";
        this.translateLanguage.bao_cao = "Report";
        this.translateLanguage.lam_to_khai_thue = "Make a tax return";
        this.translateLanguage.tai_khoan = "Account";
        this.translateLanguage.cau_hinh = "Configuration";
        this.translateLanguage.do_hang = "Unloading";
      }
      else if (this.accountInfo.language === 'cn')
      {
        this.translateLanguage.kho_hang_cn = "仓库（中国）";
        this.translateLanguage.kho_hang_vn = "仓库（越南）";
        this.translateLanguage.bao_cao = "报告";
        this.translateLanguage.lam_to_khai_thue = "进行纳税申报";
        this.translateLanguage.tai_khoan = "帐户";
        this.translateLanguage.cau_hinh = "配置";
        this.translateLanguage.do_hang = "卸货";
      }
      else if (this.accountInfo.language === 'vi')
      {
        this.translateLanguage.kho_hang_cn = "Kho hàng (TQ)";
        this.translateLanguage.kho_hang_vn = "Kho hàng (VN)";
        this.translateLanguage.bao_cao = "Báo cáo";
        this.translateLanguage.lam_to_khai_thue = "Làm tờ khai thuế";
        this.translateLanguage.tai_khoan = "Tài khoản";
        this.translateLanguage.cau_hinh = "Cấu hình";
        this.translateLanguage.do_hang = "Dỡ hàng";
      }
    },
    checkLogin() {
      var login = ls.get("out");
      if (login == true) {
        this.$emit("toggleMenu", this.showText);
        ls("out", false);
      }
    },
    // check quyền
    hasPermission(permission) {
      let permissions = [];
      if (
        undefined !== this.accountInfo.permissions &&
        this.accountInfo.permissions.length > 0
      ) {
        this.accountInfo.permissions.forEach((e) => {
          permissions.push(e.name);
        });
      }
      return permissions.includes(permission);
    },
    //
    nextRoute(name, full_path) {
      if (this.full_path !== full_path) {
        this.$router.push({ name: name });
      }
    },
    toggleMenu() {
      this.showText = !this.showText;
      this.$emit("toggleMenu", this.showText);
    },
    goto(page) {
      this.$router.push({ name: page });
    },
  },
  watch: {},
};
</script>

<style lang="css" scoped>
.component-menu-left {
  /* height: 100vh; */
}
.name-project {
  /*padding: 0px 25px;*/
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 1px;
  color: #fff;
  padding-right: 15px;
  padding-left: 10px;
}
.name-project-lite {
  padding-right: 0px;
  padding-left: 0px;
  justify-content: center !important;
}
.name-project img {
  cursor: pointer;
  padding-top: 5px;
}
.item-left {
  border-bottom: 1px solid rgba(94, 182, 231, 0.53);
  cursor: pointer;
  transition: all 0.3s;
  color: #fff;
  line-height: 50px;
  padding-left: 15px;
  font-size: 12px;
}
.item-left:hover {
  background-color: rgba(113, 176, 222, 0.58);
  transition: all 0.3s;
}
.item-left.active {
  background-color: rgba(113, 176, 222, 0.58);
}
.item-left img {
  margin-right: 12px;
  width: 18px;
}

.item-left i {
  margin-right: 12px;
  width: 18px;
}
i.el-icon-s-custom {
  font-size: 20px;
}
.line-vn {
  border: 1px solid #fff;
}
.menu-tree {
  background-color: #1975b8 !important;
  padding-left: 0px !important;
  display: inline;
  justify-content: center;
  align-items: center;
}

.el-menu-item-group {
  background-color: #4c97ce !important;
}
.text-white {
  color: #fff;
}
.el-menu-item:hover {
  background-color: #1975b8 !important;
  color: #fff !important;
  border-left: 4px solid #fff;
  font-weight: 400;
}
.el-icon-location {
  margin-left: -5px !important;
}

.menu-tree .is-active {
  font-weight: 700;
}
.el-menu-item-group .is-active {
  background-color: #1975b8 !important;
  color: #fff !important;
  border-left: 4px solid #fff;
  font-weight: 400;

  /* Fix a Tùng*/
  background-color: #ffb800 !important;
  color: #fff !important;
  border-left: 4px solid #fff;
  font-weight: 400;
}
.spanLink {
  text-decoration: none
}
</style>
<style>
.el-submenu__title:hover {
  background-color: #1975b8 !important;
}
.el-submenu__title {
  padding-left: 15px !important;
}
.el-submenu__icon-arrow {
  color: #fff !important;
}
.el-menu--inline .el-menu-item-group__title{
  padding: 0px 0px;
}
.el-menu-item-group__title{
  padding: 0px 0px !important;
}
</style>
